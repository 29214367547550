@import 'functions';

/**
 * Usage: .selector { @include organization(org) { // style rules } }
 */
@mixin organization($organizations...) {
    @if index($organizations, $organization) {
        @content;
    }
}

/**
 * Usage: .selector { @include font-properties(xl) }
 * See _typography.scss for all available font settings
 */
@mixin font-properties($val: normal, $size: false) {
    @if $size {
        font-size: $size;
    } @else {
        font-size: font-size($val);
    }

    font-family: font-family($val);
    font-weight: font-weight($val);
}

/**
 * Usage: .selector { @include apply-btn-class('btn') }
 */
@mixin apply-btn-class($class) {
    $style-class: btn-class($class);
    @extend #{'.#{$style-class}'};
}

/**
 * Usage: .selector { @include scroll() }
 * See available settings below
 */
@mixin scroll(
    $has-full-width: true,
    $has-full-height: true,
    $has-transition: true,
    $scroll-width: 10px,
    $track-bg: var(--grey-50),
    $thumb-bg: var(--grey-300),
    $thumb-bg-hover: var(--text-secondary),
    $radius: 10px,
    $is-horizontal: false,
    $has-corner: false,
    $has-left-scrollbar: false
) {
    @if $has-full-width {
        width: 100%;
    }

    @if $has-full-height {
        height: 100%;
    }

    @if $has-transition {
        transition: all 300ms linear;
    }

    overflow-y: auto;

    @if $is-horizontal {
        overflow-x: auto;
    } @else {
        overflow-x: hidden;
    }

    &::-webkit-scrollbar {
        width: $scroll-width;

        @if $is-horizontal {
            height: $scroll-width;
        }
    }

    &::-webkit-scrollbar-track {
        background-color: $track-bg;
        border-radius: $radius;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $thumb-bg;
        border-radius: $radius;
        outline: none;

        @if $thumb-bg-hover {
            &:hover {
                background-color: var(--text-secondary);
            }
        }
    }

    @if $has-corner {
        &::-webkit-scrollbar-corner {
            background-color: rgba(0, 0, 0, 0);
        }
    }

    @if $has-left-scrollbar {
        direction: rtl;

        & > * {
            direction: ltr;
        }
    }
}

/**
 * Usage: .selector { @include truncate-multi-line(2) }
 * $limit is the maximum number of lines to display
 */
@mixin truncate-multi-line($limit) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $limit;
    -webkit-box-orient: vertical;
}

/**
 * Applies truncation to elements which cannot be targeted directly via the template
 * Usage: .selector { @include truncate(); }
 */
@mixin truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin add-pseudo-shadow($direction: right, $length: 10px) {
    pointer-events: none;
    position: absolute;
    content: ' ';
    background: linear-gradient(to right, rgba(0, 0, 0, 0.1), transparent);
    height: 100%;
    width: $length;
    @if $direction == right {
        right: -$length;
    } @else if $direction == left {
        left: -$length;
    }
}
