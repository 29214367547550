@import 'palette';
@import 'mr-palette';
@import 'pp-palette';
@import 'dmi-palette';
@import 'mr-plant-palette';
@import 'pp-ingredient-profiler-palette';
@import 'dg-ingredient-analyzer-palette';
@import '../../environments/environment.generated';

/**
 * Usage: color: palette-color(blue, 100)
 * See _colors.scss for all available palette colors
 */
@function palette-color($key, $variant) {
    $map: map-get($colors, $key);
    @return map-get($map, $variant);
}

/**
 * Usage: color: theme-palette-color(primary, 800)
 * See _mr-palette.scss, pp-palette.scss, dmi-palette.scss, mr-plant-palette.scss,
 * pp-ingredient-profiler-palette.scss or dg-ingredient-analyzer-palette for all available palettes
 */
@function theme-palette-color($key, $variant) {
    // apply $mr-colors palette
    @if ($organization == 'mr') {
        $map: map-get($mr-colors, $key);
        @return map-get($map, $variant);
    }

    // apply $pp-colors palette
    @else if ($organization == 'pp') {
        $map: map-get($pp-colors, $key);
        @return map-get($map, $variant);
    }

    // apply $dmi-colors palette
    @else if ($organization == 'dmi') {
        $map: map-get($dmi-colors, $key);
        @return map-get($map, $variant);
    }

    // apply $mr-plant-colors palette
    @else if ($organization == 'mr-plant') {
        $map: map-get($mr-plant-colors, $key);
        @return map-get($map, $variant);
    }

    // apply $pp-ingredient-profiler-colors palette
    @else if ($organization == 'pp-ingredient-profiler') {
        $map: map-get($pp-ingredient-profiler-colors, $key);
        @return map-get($map, $variant);
    }

    // apply $dg-ingredient-analyzer-colors palette
    @else if ($organization == 'dg-ingredient-analyzer') {
        $map: map-get($dg-ingredient-analyzer-colors, $key);
        @return map-get($map, $variant);
    }
}
