@import 'breakpoints';

// Small devices
@mixin sm {
    @media screen and (min-width: #{$screen-sm-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media screen and (min-width: #{$screen-md-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media screen and (min-width: #{$screen-lg-min}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media screen and (min-width: #{$screen-xl-min}) {
        @content;
    }
}

// Large to Huge devices
@mixin sxxl {
    @media screen and (min-width: #{$screen-sxxl-min}) {
        @content;
    }
}

// Large to Huge devices 2
@mixin sxxl2 {
    @media screen and (min-width: #{$screen-sxxl2-min}) {
        @content;
    }
}

// Huge devices
@mixin xxl {
    @media screen and (min-width: #{$screen-xxl-min}) {
        @content;
    }
}

// Tablets to small desktops max height
@mixin max-lg-height {
    @media screen and (max-height: #{$screen-height-lg-max}) {
        @content;
    }
}

// Tablets to small desktops max width
@mixin max-lg {
    @media screen and (max-width: #{$screen-lg-max}) {
        @content;
    }
}
