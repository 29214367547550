.badge {
    @include font-properties(sm-l);
    min-width: 74px;
    letter-spacing: 0.25px;
    vertical-align: bottom;
    border-radius: 17px;
    padding: 6px 7px;

    &.badge-primary {
        background-color: var(--branding-primary);
        color: var(--white);
    }

    &.badge-secondary {
        background-color: var(--branding-secondary);
    }

    &.badge-tertiary {
        background-color: var(--branding-secondary-200);
    }
}

.badge-strength {
    @include font-properties(xs);
    min-width: 65px;
    vertical-align: bottom;
    border-radius: 4px;
    padding: 6px 7px;

    &.badge-high {
        background-color: var(--badge-high);
        color: var(--white);
    }

    &.badge-medium {
        background-color: var(--badge-medium);
        color: var(--white);
    }

    &.badge-low {
        background-color: var(--badge-low);
        color: var(--white);
    }

    &.badge-alternate {
        background-color: var(--white);
        color: var(--badge-high);
        border: 2px solid var(--badge-high);
    }
}
