@import 'colors';

/**
 * Usage: color: theme-color(branding, primary)
 * See _colors.scss for all available theme colors
 */
@function theme-color($key, $variant) {
    $map: map-get($theme-colors, $key);
    @return map-get($map, $variant);
}
