@import '../base/functions';
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@import '~@swimlane/ngx-datatable/themes/bootstrap.css';

// ngx-datatable
.ngx-datatable.bootstrap {
    @include font-properties(xs);

    &.full-height {
        height: 100% !important;
    }

    .datatable-header {
        color: var(--text-primary);
        background-color: var(--white);
        border-bottom: 2px solid var(--grey-50);

        .resize-handle {
            border-right: 1px solid var(--grey-50);
        }

        .datatable-header-cell {
            user-select: none;
            border-bottom: none;

            &.align-left {
                text-align: left;
            }
            &.align-right {
                text-align: right;
            }
            &.align-center {
                text-align: center !important;
            }
        }

        .datatable-header-cell-template-wrap {
            height: 24px;

            [class^='datatable-icon-']::before,
            [class*=' datatable-icon-']::before {
                font-family: 'Font Awesome 6 Pro' !important;
                font-weight: 900 !important;
            }

            .datatable-icon-sort-unset,
            .datatable-icon-down,
            .datatable-icon-up {
                padding-left: 5px;
            }

            .datatable-icon-sort-unset {
                color: var(--grey-600);
            }

            .datatable-icon-down,
            .datatable-icon-up {
                color: var(--branding-primary);
            }

            .datatable-icon-down {
                height: 17px;
            }

            .datatable-icon-up {
                height: 6px;
            }

            .datatable-icon-sort-unset::before {
                content: '\f0dc';
                opacity: 1;
            }

            .datatable-icon-down::before {
                content: '\f0dd';
            }

            .datatable-icon-up::before {
                content: '\f0de';
            }
        }

        .datatable-row-left {
            background-color: var(--white);
        }
    }

    &.disable-header {
        .datatable-header {
            pointer-events: none;
        }
    }

    .datatable-body {
        color: var(--text-primary);

        .empty-row {
            height: 120px;
            line-height: 6em;
            text-align: center;
            background-color: var(--list-primary);
            font-style: italic;
            position: relative;
        }

        .datatable-body-row {
            border-radius: 0;
            border-bottom: 1px solid var(--grey-50);
            margin-bottom: 0;

            &.datatable-row-even {
                background-color: var(--list-primary);

                &:hover {
                    background-color: var(--list-hover);

                    .datatable-row-left {
                        background-color: var(--list-hover);
                    }
                }

                .datatable-row-left {
                    background-color: var(--list-primary);
                }
            }

            &.datatable-row-odd {
                background-color: var(--list-secondary);

                &:hover {
                    background-color: var(--list-hover);

                    .datatable-row-left {
                        background-color: var(--list-hover);
                    }
                }

                .datatable-row-left {
                    background-color: var(--list-secondary);
                }
            }

            &.active {
                background-color: transparent;
                color: var(--text-primary);
            }

            &.highlighted {
                background-color: var(--list-active) !important;

                .predicted-relationship-type {
                    color: var(--text-primary);
                }

                .datatable-row-left {
                    background-color: var(--list-active) !important;
                }
            }
        }

        .datatable-row-detail {
            .datatable-body {
                .datatable-row-wrapper {
                    .datatable-body-row {
                        &.datatable-row-even {
                            background-color: var(--list-primary);

                            &:hover {
                                background-color: darken(color(list, primary), 1.5%);

                                .datatable-row-left {
                                    background-color: darken(color(list, primary), 1.5%);
                                }
                            }

                            .datatable-row-left {
                                background-color: var(--list-primary);
                            }
                        }

                        &.datatable-row-odd {
                            background-color: var(--list-primary);

                            &:hover {
                                background-color: darken(color(list, primary), 1.5%);

                                .datatable-row-left {
                                    background-color: darken(color(list, primary), 1.5%);
                                }
                            }

                            .datatable-row-left {
                                background-color: var(--list-primary);
                            }
                        }
                    }
                }
            }
        }
    }

    &.hide-body {
        height: 50px !important;
    }

    .datatable-row-wrapper {
        width: 100%;

        .datatable-body-row {
            border-top: none;
            min-width: 100%;

            .datatable-body-cell {
                &.align-left {
                    text-align: left !important;
                }
                &.align-right {
                    text-align: right !important;
                }
                &.align-center {
                    text-align: center !important;
                }

                .datatable-body-cell-label {
                    padding-top: 3px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                }
            }
        }
    }

    .datatable-footer {
        background-color: inherit;
        color: var(--text-primary);
        border-radius: 10px;
        overflow: hidden;
    }

    &.has-scrolled-horizontally {
        .datatable-row-left {
            &::after {
                @include add-pseudo-shadow();
            }
        }
    }
}
