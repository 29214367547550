@import 'base/functions';
@import 'base/mixins';
@import 'base/utilities';
@import 'media-queries/mixins';
@import 'components/articles';
@import 'components/buttons';
@import 'components/conversational-search';
@import 'components/data-attributes';
@import 'components/dialogs';
@import 'components/forms';
@import 'components/links';
@import 'components/reports';
@import 'components/spinners';
@import 'components/dragula';
@import 'components/quill';
@import 'components/ngb-alert';
@import 'components/ngb-popover';
@import 'components/ngb-tooltip';
@import 'components/ngx-slider';

:root {
    // base
    --white: #fff;
    --black: #000;
}

::selection {
    color: var(--text-primary);
    background-color: var(--branding-secondary-200);
}

html {
    height: 100%;
}

body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    color: var(--text-primary);
    background-color: var(--grey-50);
    text-rendering: optimizeLegibility;
    @include font-properties(normal);

    .app-container {
        height: 100vh;
        width: 100%;
        background-color: var(--grey-50);

        &.white-background {
            background-color: var(--white);
        }

        .app-body {
            width: 100%;
            height: 100%;
            display: flex;
            z-index: 1;
            position: relative;

            &.menu-horizontal {
                flex-direction: column;
                justify-content: space-between;
            }

            .chat-assistant-wrapper {
                position: absolute;
                left: 15px;
                bottom: 80px;
                z-index: 1;
            }
        }

        .app-footer {
            position: relative;
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    .app-footer,
    .pagination-wrapper {
        .info {
            @include font-properties(xs-b, 13px);
            white-space: nowrap;
            line-height: 1;

            .amount {
                @include font-properties(xs-xb, 13px);
                color: var(--branding-secondary-900);

                @include organization(dmi) {
                    color: var(--branding-primary);
                }
            }
        }
    }

    .menu-wrapper {
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        z-index: 1051;
        pointer-events: none;

        &.vertical {
            height: 100%;
        }

        .navbar-wrapper {
            pointer-events: all;
        }
    }

    .menu-inner {
        width: 100%;
        height: 100%;
    }

    .content-wrapper {
        height: 100%;
        width: 100%;
        min-width: 0; // to avoid overflowing inside the flex parent
        min-height: 0; // to avoid overflowing inside the flex parent
        position: relative;
        flex-grow: 1;
        flex-shrink: 1;

        &.includes-helper-menu {
            display: flex;
        }

        &.includes-background-image {
            position: absolute;
        }
    }

    .content {
        padding: 20px;
        @include scroll();
    }
}
