// iframes
.iframe-container {
    margin: 0 auto;
    display: block;
    width: 100%;
}

iframe {
    border: 1px solid var(--grey-100);
}

.legal-iframe {
    width: 100%;
    height: 100%;
    border: none;
}
