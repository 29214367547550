.ngx-slider {
    margin: 0 !important;

    .ngx-slider-span {
        &.ngx-slider-selection {
            background: var(--branding-primary) !important;
        }

        &.ngx-slider-bubble {
            @include font-properties(xs);
            color: var(--text-default);
            bottom: 12px;
        }

        &.ngx-slider-bar {
            background: var(--grey-300);
            height: 4.5px;
            border-radius: 2px;
        }

        &.ngx-slider-pointer {
            width: 16px;
            height: 16px;
            top: -6px;
            background-color: var(--branding-primary);
            border: 1px solid var(--white);
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);

            &::after {
                display: none !important;
            }
        }
    }

    &.disabled-slider {
        .ngx-slider-span {
            &.ngx-slider-bubble {
                color: var(--text-secondary);
            }

            &.ngx-slider-selection {
                background: var(--grey-600);
            }

            &.ngx-slider-pointer {
                display: none;
            }
        }
    }
}
