.filter-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &.solid {
        background-color: var(--text-primary);
    }

    &.non-solid {
        background-color: transparent;
    }

    &.with-border {
        border: 1px solid var(--text-primary);

        &.disabled {
            border-color: var(--text-secondary) !important;
        }
    }

    &.with-inner-circle {
        position: relative;
    }

    .inner-circle {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        position: absolute;
        background-color: var(--text-primary);
        margin: 1px;
    }
}

.hidden-filters {
    // We use visibility: hidden instead of display: none to prevent the following glitch:
    //  1. Open a selector.
    //  2. Perform an action (e.g. switch to a different tab) so that hidden-filters becomes applied.
    //  3. The selector would momentarily jump to the top-left part of the screen.
    visibility: hidden;
    position: absolute !important; // used to prevent the hidden-filters from taking up space
}
