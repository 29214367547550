// ngb-alert
.alert {
    @include font-properties(sm);
    line-height: 16px;
    position: fixed;
    margin-bottom: 0;
    z-index: 10;
    width: 320px;
    height: auto;
    bottom: 30px;
    left: 30px;
    word-break: break-word;
    border-radius: 0;
    box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.275) !important;

    &.alert-danger {
        background-color: var(--informative-danger);
        color: var(--white);
        border-color: var(--informative-danger);
    }

    &.alert-success {
        background-color: var(--informative-success);
        color: var(--white);
        border-color: var(--informative-success);
    }

    &.alert-dismissible {
        padding-right: 65px;

        .close {
            padding: 20px;
            font-size: 16px;

            &:hover,
            &:focus {
                color: var(--white);
                text-decoration: none;
                opacity: 0.75;
                transition: all 300ms ease-in-out;
            }
        }
    }
}
