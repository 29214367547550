.interactive-reference {
    color: var(--informative-info);

    &:not(.doi) {
        text-decoration: underline;
    }

    &:hover {
        opacity: 0.7;
    }
}

.reference-index-table-header {
    display: flex;
    justify-content: center;
    gap: 5px;
    line-height: 2;

    .fa-solid {
        color: var(--branding-secondary) !important;
        position: relative;
        top: 2px;
    }
}
