.profiler-main-menu-custom-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    margin-right: 10px;

    .export-selector-wrapper {
        max-width: fit-content;
    }
}

.report-top-compounds-table {
    datatable-scroller,
    datatable-body-row {
        width: 100% !important;
    }

    .datatable-header {
        @include font-properties(xs-b);
        line-height: 1;
    }

    .datatable-body {
        @include scroll(false, false, false, 10px, false, var(--grey-300), false, 17px, true, true);
    }

    .datatable-icon-sort-unset,
    .datatable-icon-down,
    .datatable-icon-up {
        margin-bottom: 2px;

        &::before {
            font-size: 10px;
            color: var(--text-primary);
        }
    }

    .reference-wrapper {
        @include font-properties(xs-b);

        &:not(:first-child) {
            margin-left: 10px;
        }
    }
}
